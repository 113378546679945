/**
 * Error 404 Page
 *
 * @see https://www.gatsbyjs.org/docs/add-404-page/
 */
import React from 'react'

// eslint-disable-next-line import/no-extraneous-dependencies
import { Header } from 'suf_storybook'
import Main from '../components/main'
import SEO from '../components/seo'

const browser = typeof window !== 'undefined' && window

const NotFoundPage = () =>
  browser ? (
    <Main headerInverted={false}>
      <SEO title="404: Not found" description="No Page found" type="404 page" />

      <article className="article">
        <div className="va" style={{ textAlign: 'center', margin: '7rem' }}>
          <Header className="heading--1" level={1}>
            Not Found
          </Header>

          <p>The page you&#39;re looking for can&#39;t be found.</p>

          <p>
            Try a search? Enter your keywords in the Search field above. Or try
            one of the links in the menu.
          </p>
        </div>
      </article>
    </Main>
  ) : null

export default NotFoundPage
